<template>
    <div class="index">
        <!-- 头部导航组件 -->
        <navbar-header></navbar-header>

        <div class="box_title_right">
            <span class="el-icon-location-outline"></span><router-link to="/"> 首页 </router-link> / <span class="name">运力大厅</span> 
        </div>
        <div class="bx inquire">
            <div class="inquire_bt">
                货源查询 <i class="el-icon-arrow-right"></i>
            </div>
            <div class="condition">
                <div class="condition_col" style="align-items: center;">
                    <div class="condition_tit">路线搜索：</div>
                    <el-cascader :options="options" v-model="searchForm.deliverCode" size="mini" style="width: 200px;" filterable clearable></el-cascader>
                    <i class="el-icon-minus" style="margin: 0 10px;color: #999;"></i>
                    <el-cascader :options="options" v-model="searchForm.receivingCode" size="mini" style="width: 200px;" filterable clearable></el-cascader>
                </div>
                <div class="condition_col">
                    <div class="condition_tit">运输类型：</div>
                    <div class="condition_province">
                        <div @click="provincesClick('')" :class="!searchForm.transportationType ? 'ok' : ''">不限</div>
                        <div v-for="(item,index) in ycTransportationList" :key="index" @click="provincesClick(item.value)"
                            :class="searchForm.transportationType == item.value ? 'ok' : ''">{{ item.name }}</div>
                    </div>
                </div>
                <div class="condition_col">
                    <div class="condition_tit">车辆类型：</div>
                    <div class="condition_province">
                        <div @click="provinces2Click('')" :class="!searchForm.vehicleType ? 'ok' : ''">不限</div>
                        <div v-for="(item,index) in ycVehicleList" :key="index" @click="provinces2Click(item.value)"
                            :class="searchForm.vehicleType == item.value ? 'ok' : ''">{{ item.name }}</div>
                    </div>
                </div>
                <div class="condition_col" style="align-items: center;">
                    <div class="condition_tit">发布时间：</div>
                    <el-date-picker v-model="searchForm.beginCreateTime" type="date" 
                        placeholder="开始时间" size="mini" style="width: 200px;" filterable
                        value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
                    <i class="el-icon-minus" style="margin: 0 10px;color: #999;"></i>
                    <el-date-picker v-model="searchForm.endCreateTime" type="date" 
                        placeholder="结束时间" size="mini" style="width: 200px;" filterable
                        value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
                </div>  
                <div style="display: flex;flex-direction: row-reverse;">
                    <div class="relation" @click="queryList(true)">
                        查询
                    </div>
                    <div class="reset" @click="reset()">
                        重置
                    </div>
                </div>
            </div>
        </div>
        <div class="bx inquire">
            <ul class="inquire_tit">
                <li>出发城市/到达城市</li>
                <li>运输类型</li>
                <li>车型</li>
                <li style="text-align: center;flex: 1;">车辆数</li>
                <li style="text-align: center;">发布时间</li>
                <li style="text-align: center;flex: 1;">运价</li>
                <li style="flex:1">&nbsp;</li>
            </ul>
            <div style="background: #fff;" class="nr">
                <div v-for="item in content" :key="item.id" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);margin-bottom: 21px;">
                    <ul style="border-bottom: 2px dashed rgba(34,31,31,0.05);">
                        <li>
                            <div class="flex_row_center" style="margin-bottom: 18px;">
                                <div class="install">装</div><div style="width: 140px;">{{item.deliverCity}} {{ item.deliverArea }}</div> </div>
                            <div class="flex_row_center" style="margin-bottom: 18px;">
                                <div class="discharge">卸</div><div style="width: 140px;">{{item.receivingCity}} {{ item.receivingArea }}</div></div>
                        </li>
                        <li class="tsxs" style="padding-right: 10px;">
                            <el-popover
                                placement="top-start"
                                width="200"
                                trigger="hover"
                                :content="item.transportationTypeStr">
                                <div slot="reference">{{ item.transportationTypeStr }}</div>
                            </el-popover>
                        </li>
                        <li class="tsxs">
                            <el-popover
                                placement="top-start"
                                width="200"
                                trigger="hover"
                                :content="item.vehicleTypeStr">
                                <div slot="reference">{{ item.vehicleTypeStr }}</div>
                            </el-popover>
                        </li>
                        <li style="text-align: center;flex: 1;">{{ item.vehiclesNum }}辆</li>
                        <li style="text-align: center;">{{ item.createdTime }}</li>
                        <li style="text-align: center;flex: 1;">{{ item.freightRate }}元</li>
                        <li style="flex:1" class="relation">
                            <el-popover
                                placement="bottom"
                                width="300"
                                trigger="click">
                                <div>
                                    <p style="font-size: 24px;color: black;">{{ item.contacts }}</p>
                                    <div style="color: #c50007;display: flex;flex-direction: row;align-items: center;">
                                        <img style="width: 20px;height: 20px;margin: 10px 4px 10px 0;" src="../assets/img/dh.png" alt=""> 
                                        <div style="font-size: 20px;">
                                            {{ item.contactsPhone }}
                                        </div>
                                    </div>
                                    <p style="font-size: 18px;">联系我时，请说明是在延长交易所上看到的</p>
                                </div>
                                <div slot="reference" style="width: 100%;">联系</div>
                            </el-popover>
                        </li>
                    </ul>
                    <div style="height: 40px; display: flex;flex-direction: row;align-items: center;">
                        <img src="../assets/img/rz.png" alt="" style="width: 22px;height: 22px;margin-top: 2px;">
                        <div style="margin-right: 10px;font-size: 14px;">
                            {{item.publisherType == '10' ? '司机发布' : item.publisherType == '20' ? '企业发布' : '平台发布'}} {{ item.publisherName || '---' }}</div>
                    </div>
                </div>
                <div style="width: 100%;display: flex;justify-content: center;margin: 200px 0;" v-if="content.length == 0">
                    <img src="../assets/img/zwsjlist.png" alt="">
                </div>
                <div style="display: flex;flex-direction: row-reverse;margin-top: 10px;">
                    <el-pagination
                        background  
                        layout="prev, pager, next"
                        :total="total"
                        @current-change="current">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <nav-footer></nav-footer>

    </div>
</template>

<script>
import NavbarHeader from "../components/home/newHeader.vue"
import NavFooter from "../components/common/NavFooter.vue"
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
export default {
    components: {
        NavbarHeader,
        NavFooter
    },
    data () {
        return {
            addressArr: [],
            value1: null,
            value: '',
            optionsArr: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }],
            options: regionData,
            provinces: ["一类（爆炸品）", "二关（压缩,液化气体）", "三类（易燃液体）", "四类（易燃固体，自燃物品和遇湿易燃）",
                "五关（氧化剂，有机过氢化物）", "六类（毒害品，感染类物品）", "七类（放射性物质)", "八关（腐蚀品）",
                "九类（危险废弃物）", "昔货"
            ],
            provinces2: [
                "不限", "罐车", "二关（压缩,液化气体）", "不锈钢罐车铝合金罐车", "保温罐车",
                "铝罐车", "衬塑罐车"
            ],
            total: 0,
            searchForm: {
                pageSize: 10,
                pageNumber: 1
            },
            content: [],
            ycVehicleList: [],
            ycTransportationList: []
        }
    },
    computed: {

    },
    mounted () {
        this.queryList();
        this.dictList('yc_vehicle_type', 'ycVehicleList');//包装列表
        this.dictList('transportation_type', 'ycTransportationList');//包装列表
    },
    methods: {
        reset () {
            this.searchForm.deliverCode = null
            this.searchForm.receivingCode = null
            this.searchForm.transportationType = null
            this.searchForm.vehicleType = null
            this.searchForm.beginCreateTime = null
            this.searchForm.endCreateTime = null
        },
        queryList (isHomePage) {
            console.log(this.searchForm);
            if (isHomePage) {
                this.searchForm.pageNumber = 1;
            }
            let serverUrl = this.$serverUrl + '/wz/api/vehiclesourcelist'
            this.$axios.post(serverUrl, this.searchForm).then(res => {
                if (res.data.code == '200') {
                    this.content = res.data.data.content
                    this.total = res.data.data.totalElements
                }
            })
        },
        //切换页数
        current (val) {
            this.searchForm.pageNumber = val
            this.queryList()
        },
        //货物地址选择
        provincesClick (item) {
            this.searchForm.transportationType = item
            this.searchForm = { ...this.searchForm }
        },
        provinces2Click (item) {
            this.searchForm.vehicleType = item
            this.searchForm = { ...this.searchForm }
        },
        /**
         * 调取数据字典
         */
        dictList (infoType, name) {
            if (!infoType) {
                return;
            }
            return new Promise((resolve, reject) => {
                var params = {
                    infoType: infoType,
                    pageSize: 999
                }
                let serverUrl = this.$serverUrl + '/wz/api/dictlist'
                this.$axios.get(serverUrl, { params: params }).then(res => {
                    if (res.data.code == '200') {
                        var resultList = res.data.data;
                        this[`${name}`] = resultList;
                        resolve();
                    }
                })
            })
        }
    },
}
</script>
<style>
.el-dialog__wrapper {
    z-index: 100000000 !important;
}
.el-select-dropdown {
    z-index: 100000000000000000000000 !important;
}
.el-picker-panel {
    z-index: 100000000000000000000000 !important;
}
</style>

<style lang="scss" scoped>
.index {
    width: 100%;
    background: #f5f5f5;
    &::-webkit-scrollbar {
        display: none;
    }
    .name {
        font-size: 14px;
        color: #000;
    }
}
.box_title_right {
    width: 1200px;
    margin: 0 auto;
    font-size: 15px;
    color: #999999;
    padding: 20px 0;
    a {
        color: #999999;
    }
}
.inquire {
    margin-bottom: 20px;
    .inquire_bt {
        background: #ececec;
        padding-left: 20px;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
    }
    .condition {
        background: #fff;
        padding: 20px;
        font-size: 14px;
        .condition_col {
            display: flex;
            flex-direction: row;
            font-size: 16px;
            margin: 20px;
            .condition_tit {
                line-height: 30px;
                width: 100px;
            }
            .condition_province {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                div {
                    height: 30px;
                    border-radius: 3px;
                    text-align: center;
                    line-height: 30px;
                    margin-right: 10px;
                    border: 1px solid transparent;
                    padding: 0 10px;
                    cursor: pointer;
                    &:hover {
                        color: #c50007;
                    }
                }
            }
        }
    }
    .inquire_tit {
        display: flex;
        justify-content: space-between;
        background: #ececec;
        padding: 0 20px;
        font-size: 14px;
        height: 40px;
        font-weight: 600;
        line-height: 40px;
        li {
            flex: 2;
        }
    }
    .nr {
        padding: 20px;
        ul {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            align-items: center;
            li {
                flex: 2;
            }
        }
        img {
            margin-right: 4px;
        }
        .turnover_time {
            color: rgba(0, 0, 0, 0.26);
        }
    }
}
.install {
    width: 24px;
    height: 24px;
    background: #34cb91;
    color: #fff;
    text-align: center;
    line-height: 24px;
    margin-right: 10px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
}
.discharge {
    width: 24px;
    height: 24px;
    background: #ff4238;
    color: #fff;
    text-align: center;
    line-height: 24px;
    margin-right: 10px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
}
.flex_row_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}
/deep/ .active {
    background-color: #c50007 !important;
}
.relation {
    width: 60px;
    cursor: pointer;
    // padding: 0 10px;
    height: 30px;
    background-color: #c50007 !important;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
}
.reset {
    width: 60px;
    cursor: pointer;
    padding: 0 10px;
    border: 1px solid #c50007;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    color: #c50007;
    margin-right: 10px;
}
.tsxs {
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.ok {
    color: #c50007;
    border: 1px solid #c50007 !important;
}
</style>